import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './Info.css'
const Info = () => {
    const controls = useAnimation();
    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView) {
          controls.start('visible');
        }
        if (!inView) {
          controls.start('hidden');
        }
      }, [controls, inView]);

    const boxVariants = {
        hidden: { y: -100, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                type:"spring",
                stiffness: 100
            }
        }
    }
    const boxVariants2 = {
        hidden: { y: -100, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 0.3,
                duration: 0.5,
                type:"spring",
                stiffness: 100
            }
        }
    }
    const boxVariants3 = {
        hidden: { y: -100, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5,
                type:"spring",
                stiffness: 100
            }
        }
    }
    const boxVariants4 = {
        hidden: { y: -100, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.7,
                type:"spring",
                stiffness: 100
            }
        }
    }
    const boxVariants5 = {
        hidden: { y: -100, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.9,
                type:"spring",
                stiffness: 100
            }
        }
    }

    return (
        <div className='info-container'>
            <motion.div ref={ref} className='info-title' initial="hidden" animate={controls} variants={boxVariants}>
                <div className='white'>IF YOU DON’T KNOW WHAT AN </div>
                <div className='blue'>NFT</div>
                <div className='white'> IS</div>
            </motion.div>
            <div className='content'>
                <motion.div ref={ref} initial="hidden" animate={controls} variants={boxVariants2}>USING YOUR WINDOWS PC PLEASE PRESS ALT+F4, THEN CLICK ENTER</motion.div>
                <motion.div ref={ref} initial="hidden" animate={controls} variants={boxVariants3}>USING YOUR MAC OS OVERPRICED DEVICE,
                    PLEASE THROW IT OUT THE NEAREST WINDOW</motion.div>
                <motion.div ref={ref} initial="hidden" animate={controls} variants={boxVariants4}>USING YOUR PHONE, PLEASE CALL YOUR MOM 
                    AND TELL HER HOW MUCH YOU LOVE HER, THEN WAIT UNTIL THE BATTERY DIE AND CRACK THE SIM CARD.
                    YOU ARE NOW THE NEXT JAMES BOND. RUN.</motion.div>
                <motion.div ref={ref} className='row' initial="hidden" animate={controls} variants={boxVariants5}>
                    <div className='white'>ANYWAY </div>
                    <div className='yellow'>, DON’T SCROLL DOWN</div>
                </motion.div>
            </div>
        </div>
    )
}

export default Info