import React, { useEffect, useState } from "react";

import './Mint.css'

import { motion } from "framer-motion"
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

import Account from "../utils/account";
import { mint, supply } from "../utils/interactions";

const Mint = () => {

    const {state, authenticate, logout} = Account();
    const {isAuthenticated} = state;
    const [totalSupply, setTotalSupply] = useState(0)
    const [amount, setamount] = useState(1)

    const [txHash, setTxHash] = useState("")
    const [isLoading, setisLoading] = useState(false)
    const [confirmed, setConfirmed] = useState(false)
    const [success, setSuccess] = useState(false)

    const [isError, setisError] = useState(false)
    const [error, seterror] = useState("")

    const isMobile = window.innerWidth < 768;

    useEffect(() => {
      async function getSupply(){
        const s = await supply();
        setTotalSupply(s.toNumber())
      }
      getSupply()
    })
    

    const calculateTimeLeft = () => {

        let difference = +new Date(`04/12/2022 21:00:00`) - +new Date();
        // let difference = +new Date(`04/12/2022 21:00:00`) - +new Date();

        let timeLeft = {};
      
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          };
        }
      
        return timeLeft;
    }
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer = setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
      
        return () => clearTimeout(timer);
    });
    const timerComponents = [];
    let c=0;
    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
          return;
        }
      
        timerComponents.push(
          <span key={c}>
            {timeLeft[interval]} {interval}{" "}
          </span>
        );
        c+=1;
    });

    function count(x){
      amount + x < 1 ? setamount(amount) : setamount(amount +x);
    }

    useEffect(() => {
      if(txHash !== ""){
          setisLoading(false)
      }
     }, [txHash])

    const mintx = async() => {
      setisLoading(true);
      mint(amount).then(async (tx)=>{
        setTxHash(tx.hash);
        const receipt = await tx.wait();
        // console.log(receipt)
        setSuccess(false);
        setConfirmed(true);
      
    }).catch((e)=>{
        let error = ""
        e.error ? error=e.error.message : error = e.message
        
        setisError(true);
        seterror(error)
        setisLoading(false)
        setSuccess(false);
        setConfirmed(false);
        setTxHash("");
    })

    }
    return (
        <div className='mint-container'>
            {timerComponents.length 
            ?<motion.div className='title'
                initial={{x: -100, opacity: 0}}
                animate={{ x: 0, opacity: 1.0 }}
                transition={{ duration: 0.6, delay: 0.5 }}
                >MINT GOES LIVE IN
            </motion.div>
            :<div></div>
            }
            <motion.div className='counter'
                initial={{x: 100, opacity: 0}}
                animate={{ x: 0, opacity: 1.0 }}
                transition={{ duration: 0.6 , delay: 0.5 }}
                >{timerComponents.length 
                ? timerComponents 
                :<span></span>}
            </motion.div>
            {timerComponents.length 
            ?<div></div>
            :!isAuthenticated
              ?<Button label={"Connect Wallet"} style ={{color:'#fecd27', background: "#3333ff00",  border:"1px solid #808080", width: "157px"}} className=" p-button-info p-button-text" onClick={() =>{authenticate()}} />
              :<>
                {isLoading
                ?<div className='load'>
                    <br/>
                    <ProgressSpinner />
                    <br/>
                    <br/>
                    <div className='message'>Confirm transaction in your wallet...</div>
                </div>
                :txHash !== "" 
                ? !confirmed 
                ?<div className='message'>
                    <ProgressSpinner />
                    <br/>
                    <br/>
                   
                    Your transaction has been submitted and is awaiting confirmation. You can view your transaction <a href={"https://rinkeby.etherscan.io/tx/" + txHash} target="_blank" rel="noopener noreferrer">here</a>
                    <br/>
                </div> 
                : <div className='message'>
                    <div className='success yellow'>SUCCESS</div>
                    <br/>
                   
                    <div></div>
                    
                    
                </div> 
                
                :<>
                
                <div className="counter-container">
                  <div className="pi pi-angle-left" onClick={()=>{count(-1)}} style={{cursor:"pointer", 'fontSize': '3em'}}></div>
                  <div className="amount yellow">{amount}</div>
                  <div className="pi pi-angle-right" onClick={()=>{count(1)}} style={{cursor:"pointer", 'fontSize': '3em'}}></div>
                </div>
                <Button label={"Mint"} style ={{color:'#fecd27', background: "#3333ff00",  border:"1px solid #808080", width: "157px"}} className=" p-button-info p-button-text" onClick={()=>mintx()} />
                <div className="minted">minted {totalSupply} of 2222</div>
                </>
                } 
              </>
            
            }
            
        </div>
    )
}

export default Mint