import React from 'react'

import './Bar.css'

import { motion } from "framer-motion"
const Bar = () => {
    
    return (
        <>
            <motion.div className='bar'
                initial={{width: 0, opacity: 0}}
                animate={{ width: '100%', opacity: 1.0 }}
                transition={{ duration: 0.6, delay: 0.6  }}
            >
                <div></div>
                <div></div>
                <motion.div className='bar-txt'
                
                    initial={{y: -100, opacity: 0}}
                    animate={{y: 0, opacity: 1.0 }}
                    transition={{ duration: 0.4, delay: 0.8 , type: "spring", stiffness: 100}}
                
                >
                    <p className='regular'>NO</p>
                    <p className='cntr'>DOXXED</p>
                    <p className='regular'>TEAM</p>
                </motion.div>
                <motion.div className='bar-txt'
                    initial={{y: -100, opacity: 0}}
                    animate={{y: 0, opacity: 1.0 }}
                    transition={{ duration: 0.4, delay: 0.9 , type: "spring", stiffness: 100}}
                >
                    <p className='regular'>OVER</p>
                    <p className='cntr'>100</p>
                    <p className='regular'>TRAITS</p>
                </motion.div>
                <motion.div className='bar-txt'
                    initial={{y: -100, opacity: 0}}
                    animate={{y: 0, opacity: 1.0 }}
                    transition={{ duration: 0.4, delay: 1 , type: "spring", stiffness: 100}}
                >
                    <p className='regular'>100%</p>
                    <p className='cntr'>DEGEN</p>
                    <p className='regular'>PLAY</p>
                </motion.div>
                <div></div>
                <div></div>
            </motion.div>
        
        </>
        
    
    )
}

export default Bar