
import { motion } from "framer-motion"

import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import './App.css';

import Carousel from './components/Carousel';
import Bar from './components/Bar';
import Mint from "./components/Mint";
import Info from "./components/Info";
import Roadmap from "./components/Roadmap";

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-225875405-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


function App() {

  

  return (
    <div className="App">
      <motion.div className='header'
         initial={{y: -100, opacity: 0}}
         animate={{y: 0, opacity: 1.0 }}
         transition={{ duration: 0.4 , type: "spring", stiffness: 100}}
        >EMOTIONAL DAMAGE
      </motion.div>
      <Mint />
      <Carousel />
      <Bar />
      <Info />
      <Carousel />
      <Roadmap />
    </div>
  );
}

export default App;
