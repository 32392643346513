import {useState, useEffect} from 'react'

import { motion, useViewportScroll } from "framer-motion"

import './Carousel.css'
import Mint from './Mint'
const Carousel = () => {


  const [yPos, setYpos] = useState(0)

  const images = [
    {id:1, url:'/images/1.png'},
    {id:2, url:'/images/2.png'},
    {id:3, url:'/images/3.png'},
    {id:4, url:'/images/4.png'},
    {id:5, url:'/images/5.png'},
    {id:6, url:'/images/6.png'},
    {id:7, url:'/images/7.png'},
    {id:8, url:'/images/8.png'},
    {id:9, url:'/images/9.png'},
    {id:10, url:'/images/10.png'},
    {id:11, url:'/images/11.png'},
    {id:12, url:'/images/12.png'},
    {id:13, url:'/images/13.png'},
    {id:14, url:'/images/14.png'},
    {id:15, url:'/images/15.png'},
    {id:16, url:'/images/16.png'},
    {id:17, url:'/images/17.png'},
    {id:18, url:'/images/18.png'},
  ]
  const images2 = [
    {id:1, url:'/images/11.png'},
    {id:2, url:'/images/12.png'},
    {id:3, url:'/images/13.png'},
    {id:4, url:'/images/14.png'},
    {id:5, url:'/images/15.png'},
    {id:6, url:'/images/16.png'},
    {id:7, url:'/images/17.png'},
    {id:8, url:'/images/18.png'},
    {id:9, url:'/images/19.png'},
    {id:10, url:'/images/20.png'},
    {id:11, url:'/images/21.png'},
    {id:12, url:'/images/1.png'},
    {id:13, url:'/images/3.png'},
    {id:14, url:'/images/4.png'},
    {id:15, url:'/images/5.png'},
    {id:16, url:'/images/6.png'},
    {id:17, url:'/images/7.png'},
    {id:18, url:'/images/8.png'},
  ]
  useEffect(() => {
    function handleScroll(){
      const yPos = window.scrollY;
      setYpos(yPos);
    }
    window.addEventListener("scroll", handleScroll, false)
    return () =>{
      window.removeEventListener("scroll", handleScroll, false)
    }
  }, [yPos])
  
  return (
    <div className='carousel-container'>
      
      <motion.div className='tt'
        initial={{x: -100, opacity: 0}}
        animate={{ x: 0, opacity: 1.0 }}
        transition={{ duration: 0.3, delay: 1.2 }}
        style= {{translateX: yPos}}
      >
        {images.map((props) =>
        <div key={props.id}>
          <motion.img className='carousel-img' src={props.url} alt=""  whileHover={{
              scale: 1.2,
              transition: { duration: 0.2 },
            }} />
        </div>
        )}
      </motion.div>
      
      <motion.div className='tt'
        initial={{x: 100, opacity: 0}}
        animate={{ x: 0, opacity: 1.0 }}
        transition={{ duration: 0.3, delay: 1.4 }}
        style= {{translateX: -yPos}}
      >
        {images2.map((props) =>
        <div key={props.id}>
          
          <motion.img className='carousel-img' src={props.url} alt=""  whileHover={{
              scale: 1.2,
              transition: { duration: 0.2 },
            }}/>
        </div>
        )}
      </motion.div>
    </div>
  )
}

export default Carousel