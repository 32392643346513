
import { ethers } from "ethers"
import {ed_abi} from './ABI' 


const edAddress = "0x6312Dd15C81C93b48E303FA78A8cf6CF9ecAdAeA"





const initContracts = () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const edContract = new ethers.Contract(edAddress, ed_abi, signer);


    return {edContract}
}


const supply = async( )=> {
    if(!window.ethereum){
        return false
    } 
    const {edContract} = initContracts();
    const tx = await edContract.totalSupply();
    return tx;
}
const mint = async ( amount) =>{
    if(!window.ethereum){
        return false
    } 
    
    const {edContract} = initContracts();
    // let price = await edContract.cost()
    // price = price.mul(amount.toString())
    // console.log(price.toNumber())
    let price = 0.022 * amount
    const tx = await edContract.mint(amount,{value:ethers.utils.parseEther(price.toString())});
    console.log(tx)
    return tx;
}


export {mint, supply}