import {useEffect} from 'react'

import './Roadmap.css'
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
const Roadmap = () => {

  return (
    <div className='roadmap-container'>
        <div className='what-we-might-do'>
            <div className='white'>HERE'S WHAT WE MIGHT DO</div>
            <div className='gray'>(BUT PROBABLY WON'T)</div>
        </div>
        <div className='merch'>
            <div className='column'>
                <div className='t yellow'>EXCLUSIVE MERCH LINE</div>
                <div className='p white'>THE MOCKUP LOOKS COOL,</div>
                <div className='p white'>BUT YEA... NOT GONNA HAPPEN</div>
            </div>
            <div className='column'>
                <img className='merch-img' src="/images/x/merch.png" alt="" />
            </div>
        </div>
        <div className='merch comic'>
            <div className='column'>
                <img className='comic-img ' src="/images/x/comic.png" alt="" />
            </div>
            <div className='column right'>
                <div className='t blue'>EITHER A COMIC BOOK</div>
                <div className='t blue'>OR AN ANIMATED SERIES</div>
                <div className='p white'>WE ARE AWARE THAT EVERY NFT PROJECT SHOULD</div>
                <div className='p white'>HAVE ON, SO KEEP YOUR FINGERS CROSSED</div>
            </div>
        </div>
        <div className='merch mobile' >
            <div className='column'>
                <div className='t yellow'>MOBILE PLAY TO EARN GAME</div>
                <div className='p white'>YEAH, RIGHT... JUST GO STAKE $LOOKS</div>
            </div>
            <div className='column'>
                <img  className='mobile-img' src="/images/x/mobile.png" alt=""/>
            </div>
        </div>
        <div className='what-we-will-do'>
            <div className='white'>HERE'S WHAT WE ACTUALLY WILL DO</div>
        </div>
        <div className='mint-info'>
            <div className='column center'>
                <div className='small'>SUPPLY</div>
                <div className='big'>2222</div>
            </div>
            <div className='column center'>
                <div className='small'>PUBLIC MINT</div>
                <div className='big'>APRIL 17TH</div>
            </div>
            <div className='column center'>
                <div className='small'>ETH PRICE</div>
                <div className='big'>0.022</div>
            </div>
        </div>
        <div className='sold-out'>
            <div className='t1 blue'>IF 100% SOLD OUT, WE WILL (FOR REAL)</div>
            <div className='p1 white'>GIVE AWAY 10 HIGH TIER NFTS - INCLUDING PAK, FVCKRENDER, 
                                WORLD OF WOMEN GALAXY,  FELINE FIENDZ AND MORE...</div>
            <div className='p1 gray'>* HOLDERS ONLY *</div>
            <br/>
            <br/>
            <div className='p1 white'>GIVE AWAY 2 ETH ON DISCORD USING GIVEAWAY BOT</div>
            <div className='p1 gray'>* HOLDERS ONLY *</div>
            <br/>
            <br/>
            <div className='p1 white'>GIVE AWAY 2 ETH ON TWITTER USING TWITTERPICKER</div>
            <div className='p1 gray'>* FOLLOWERS ONLY *</div>
            <br/>
            <br/>
            <div className='p1 yellow'>JUST GET YOUR MF EMOTIONAL DAMAGE NFT TO PARTICIPATE
                                        AND FLIP IN 2 DAYS AFTER MINT <p className='red'>*STONKS*</p></div>
            <br/>
            <br/>
        </div>
        <div className='merch dark'>
            
            <div className='column ready'>
                <div className='tx red'>ARE YOU READY?</div>
                <div className='tx blue'>HE IS.</div>

            </div>
            <div className='column tx'>
                <img className='clown-img ' src="/images/x/ready.png" alt="" />
            </div>
        </div>
    </div>
  )
}

export default Roadmap