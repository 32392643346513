import {useEffect, useReducer, useState} from 'react';
import { ethers } from "ethers";

function loginReducer(state, action) {
    switch (action.type) {
      case 'login': {
        return {
          ...state,
          account: action.account,
          isLoading: false,
          isAuthenticated: true,
          isError: false,
          network: false,
        };
      }
      case 'logout': {
        return {
          ...state,
          account: '',
          isAuthenticated: false,
          isError: false,
          network: false,
        };
      }
      case 'reset': {
        return action.payload;
      }
      case 'error': {
        return {
          ...state,
          isError: action.isError,
          network: false,
          
        };
      }
      case 'loading': {
        return {
          ...state,
          isLoading: true,
          network: false,
          
        };
      }
      case 'wrongNetwork': {
        return {
          ...state,
          network: true,
          
        };
      }
      default: {
        return state;
      }
    }
  }
  
const initialState = {
    account: '',
    isLoading: false,
    isAuthenticated: false,
    network: false, 
    isError: false,

}

export default function Account() {
  
    const [state, dispatch] = useReducer(loginReducer, initialState)
    const [wrongNetwork, setwrongNetwork] = useState(true)

    useEffect(() => {
        if(window.ethereum) {
            window.ethereum.on('chainChanged', () => {
            logout();
            })
            window.ethereum.on('accountsChanged', () => {
            logout();
            })
          }
        return(()=>{
          if(window.ethereum) {
            window.ethereum.on('chainChanged', () => {})
            window.ethereum.on('accountsChanged', () => {})
          }
        })
    })
    // useEffect(() => {
    //   async function checkNetwork(){
    //     const networkId = await window.ethereum.request({ method: 'eth_chainId' });
    //     if(networkId !=="0x1"){
    //       setwrongNetwork(true)
    //       dispatch({type: 'wrongNetwork' });
    //       alert("Please switch to mainnet ethereum network and try again.");
    //     }else{
    //       setwrongNetwork(false)
    //     }
    //   }
    //   checkNetwork()
    // }, [])
    
    
  
    const authenticate = async () => {
        dispatch({type: 'loading'});
        if(window.ethereum){
            const networkId = await window.ethereum.request({ method: 'eth_chainId' });
            dispatch({type: 'error', isError: false });

            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const accounts = await provider.send("eth_requestAccounts", []);

            dispatch({type: 'login', 
                      account: accounts[0],
                    });
        }
    }
    const logout = () => {
      dispatch({type: 'logout', });
    }
    return {state, authenticate, logout};
}